$color-highlight: #9a2f1a;
$color-text: #161616;
$color-text-dimmed: #666;
$color-error: #ff4e41;

@mixin vbcn-base-style {
  background: #fff;
  color: $color-text;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

@mixin vbcn-button {
  background: #535353;
  color: #fff;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  padding: 0.5em 0.8em;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5em 0 0 0.5em;

  &:last-of-type {
    margin-right: 0;
  }

  &:disabled {
    color: #888;
    cursor: default;
  }

  &.primary {
    background: $color-highlight;
    color: #fff;
    font-weight: 400;
  }
}

@mixin vbcn-text-small {
  font-size: 0.75em;
  color: $color-text-dimmed;
}
